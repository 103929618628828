<template>
  <div style="color: #868686">
    <el-icon class="el-icon-warning-outline"></el-icon>
    <span> {{ option }}</span>
  </div>
</template>

<script>
export default {
  name: "Description",
  components: {},
  props: {
    option: {
      type: String,
      default: "只是一段说明文字",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>
